import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import { toast } from "react-toastify";
import { ManageWebAppApiServices } from "../../utils/api/ManageWebAppApiServices";
import { getImageUrl } from "../../utils/common.utils";
import { CircularProgress } from "@mui/material";
import Message from "./message/Message";
const referralCommission =
  process.env.REACT_APP_REFERRAL_COMMISSION_PERCENTATAGE;

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.REACT_APP_NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = '/';
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");

  const [state, setState] = useState({
    isLoading: false,
    allGamesList: [],
    isLoadingGetSettings: false,
    referrals: 5,
  });
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  const getGmesData = async () => {
    const res = await ManageWebAppApiServices?.getGames();
    console.log("res>>", res);
    if (res?.data?.success) {
      // setState({
      //   ...state,
      //   isLoading: false,
      //   allGamesList: res?.data?.data?.docs,
      // });

      return {
        data: res?.data?.data?.docs,
        success: true,
      };
    } else {
      toast.error(res?.data?.data?.message || "Internal server error!");
      return {
        data: null,
        success: false,
      };
    }
  };

  const getSettingsApiData = async () => {
    const res = await ManageWebAppApiServices?.getWebAppSettings();
    if (res?.data?.success) {
      // setState({
      //   ...state,
      //   isLoadingGetSettings: false,
      //   referrals: res?.data?.data?.referrals?.metadata?.referral_percents,
      // });

      return {
        data: res?.data?.data?.referrals?.metadata?.referral_percents,
        success: true,
      };
    } else {
      toast.error("Internal server error!");
      return {
        data: null,
        success: false,
      };
    }
  };

  const getApiData = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await Promise.all([getGmesData(), getSettingsApiData()]);

    if (res[0]?.success && res[1]?.success) {
      setState({
        ...state,
        isLoading: false,
        allGamesList: res[0]?.data,
        referrals: res[1]?.data,
      });
    }

    // console.log("res>>", res);
    // if (res?.data?.success) {
    //   setState({
    //     ...state,
    //     isLoading: false,
    //     allGamesList: res?.data?.data?.docs,
    //   });
    // } else {
    //   toast.error(res?.data?.data?.message || "Internal server error!");
    // }
  };

  const getGameUrl = (gameName) => {
    const myArray = gameName.split(" ");
    var updateUrl;
    if (myArray?.length === 1) {
      updateUrl = `/Homepage/${myArray[0]}`;
    } else if (myArray?.length === 2) {
      updateUrl = `/Homepage/${myArray[0]}%20${myArray[1]}`;
    } else if (myArray?.length === 3) {
      updateUrl = `/Homepage/${myArray[0]}%20${myArray[1]}%20${myArray[2]}`;
    } else if (myArray?.length === 4) {
      updateUrl = `/Homepage/${myArray[0]}%20${myArray[1]}%20${myArray[2]}%20${myArray[3]}`;
    } else if (myArray?.length === 5) {
      updateUrl = `/Homepage/${myArray[0]}%20${myArray[1]}%20${myArray[2]}%20${myArray[3]}%20${myArray[4]}`;
    }
    return updateUrl.trim();
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
    getApiData();
  }, []);

  console.log("state>>>", state);

  return (
    <>
      <Header user={userAllData} />

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "60px" }}>
          <div
            className="alert-box"
            style={{
              color: "red",
              backgroundColor: "#F0DC24",
              padding: "9px 7px 2px 7px",
            }}
            role="alert"
          >
            <marquee behavior="scroll" direction="left">
              <b>
                How To Play
                यदि दोनों प्लेयर्स ने कोड Join करके गेम प्ले कर लिया हो और दूसरा प्लेयर गोटी ओपन होने के बाद लेफ्ट मरता है तो Opponent प्लेयर पूरा Lose दिया जायेगा
                यदि दोनों Players गेम प्ले के लिए स्टार्ट करते है एक प्लेयर गेम मे चला गया बल्कि दूसरा प्लेयर गेम मैं नही गया और गेम प्ले हो गया दोनों तरफ से एक प्लेयर की गोटी ओपन हो गई और दूसरा प्लेयर ऑटो Exit है जो ऑटो एग्जिट प्लेयर है वो पूरा लूज़ दिया जायेगा अतः अपना नेट प्रॉब्लम चेक करके खेले ये स्वयं की जिम्मेदारी होगी
                Game समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है अन्यथा Opponent के रिजल्ट के आधार पर गेम अपडेट कर दिया जायेगा चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में कोई बदलाव नहीं किया जा सकता है !
                Win होने के बाद आप गलत स्क्रीनशॉट डालते है तो गेम को सीधा Cancel कर दिया जायेगा इसलिए यदि आप स्क्रीनशॉट लेना भूल गए है तो पहले Live Chat में एडमिन को संपर्क करे उसके बाद ही उनके बताये अनुसार रिजल्ट पोस्ट करे !
                दोनों प्लेयर की टोकन (काटी) घर से बाहर न आयी हो तो लेफ्ट होकर गेम कैंसिल किया जा सकता है ! [कैंसिल प्रूफ करने के लिए वीडियो आवश्यक होगा] 'कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे अन्यथा वो कैंसिल ही माना जायेगा
                'कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे अन्यथा वो कैंसिल ही माना जायेगा
                एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है इसलिए सोच समझकर रिजल्ट पोस्ट करे गलत रिजल्ट डालने पर पेनल्टी भी लगायी जाएगी चाहे आपने वो गलती से डाला हो या जान भुजकर
                Fresh आईडी गेम के मामले में केवल पासा के उपयोग के प्रमाण पर रद्द कर दिया जाएगा गोटी के खुले होने के बावजूद नहीं ? गेम का रिकॉड होना जरूरी है अगर कोई अपनी आईडी का नाम नो फ्रेश कर रका तो नो फ्रेश आईडी ही टेक करे जिनका इंटरेस्ट हो वही टेक करे टेबल को ( इसका अंतिम रिजल्ट एडमिन देगा )
                Commission Rates
                Battle below ₹ 250, 10% commission will be charged on battle amount. ₹ 250 से कम की बैटल, बैटल राशि पर 10% कमीशन लिया जाएगा।
                Battle between ₹ 250 to ₹ 500, flat ₹ 25 commission will be charged. ₹ 250 से ₹ 500 के बीच बैटल,फ्लैट ₹ 25 कमीशन लगेगा।
              </b>
            </marquee>
          </div>
          {/* <div class="alert alert-primary" role="alert">
            ◉ वेबसाइट/App में वॉलेट बेलेंस नही दिख रहा हो , बेटल नही सेट हो रही
            हो ,रुपये डिपॉजिट नही हो रहे तो तुरंत व्हाट्सएप पर संपर्क करें ।
            बेहतर अनुभव के लिये chrome ब्राउज़र में open करे व ब्राउज़र को अपडेट
            करे । किसी भी समस्या के लिए 9257258679 पर Whatsapp व कॉल करें। Otp
            किसी को ना देवे!
          </div> */}
          <div
            className="collapseCard-container"
            style={{ backgroundColor: "#442603" }}
          >
            <div className="collapseCard">
              <Link to={"/refer"} style={{ textDecoration: "none" }}>
                <div
                  className="collapseCard-body"
                  style={{
                    height: "64px",
                    opacity: 1,
                    transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                  }}
                >
                  <div className="blink collapseCard-text text-dark">
                    {" "}
                    <span className="text-danger">
                      {" "}
                      Refer & Earn {state?.referrals}% Commission{" "}
                    </span>
                  </div>
                </div>
              </Link>

              <div
                className="collapseCard-header"
                style={{ left: "22px", transition: "left 0.3s ease 0s" }}
              >
                <picture>
                  <img
                    height="10px"
                    width="14px"
                    src="/Images/LandingPage_img/global-rupeeIcon.png"
                    alt=""
                  />
                </picture>
                <div className="collapseCard-title ml-1 mt-1">Refer & Earn</div>
              </div>
            </div>
          </div>

          {/*<div className="gameCard pt-2 " >
                    <h5 className="d-none pt-2  text-danger d-block text-center">
                    ◉ बैंक सर्वर की वजह से डिपोजिट मै समस्या आ रही है जो अगले 24 से 48 घंटे मै सॉल्व हो जायेगी।
                    </h5>
                </div>
                
                <div className="gameCard pt-2 " >
                    
                    <h5 className="d-none pt-2  text-success d-block text-center">
                    <a className="text-success" href="https://api.whatsapp.com/send?phone=+918290429001&text=Hello">
                        ◉ किसी भी समस्या के लिए 8290429001 पर whatsapp मैसेज और कॉल करें।
                    </a>
                    </h5>
                    
                </div>*/}

          <section className="games-section p-3">
            <div
              className="d-flex align-items-center games-section-title"
              style={{ color: "white" }}
            >
              Our Tournaments
            </div>

            {state?.isLoading ? (
              <div className="game_loading_container">
                <CircularProgress
                  thickness={3}
                  style={{ width: 30, height: 30 }}
                />
                <p>Fetching games...</p>
              </div>
            ) : state?.allGamesList?.length === 0 ? (
              <div className="Games_not_fount">
                <p>Games not found</p>
              </div>
            ) : (
              <div className="games-section-headline mt-2 mb-1">
                <div className="games-window ">
                  {state?.allGamesList?.map((gameData) => {
                    if (gameData?.enable) {
                      return (
                        <Link
                          className="gameCard-container mt-4"
                          to={getGameUrl(gameData?.game_name)}
                        >
                          <span className="d-none blink text-danger d-block text-right">
                            ◉ LIVE
                          </span>
                          <picture className="gameCard-image">
                            <img
                              width="100%"
                              src={getImageUrl(gameData?.thumbnail_url)}
                              alt=""
                            />
                          </picture>
                          <div className="gameCard-title">
                            <span className="d-none blink text-success d-block text-right">
                              ◉ {gameData?.game_name}
                            </span>
                          </div>
                          <div className="goverlay">
                            <div className="text">Comming Soon</div>
                          </div>
                        </Link>
                      );
                    }
                  })}
                </div>
              </div>
            )}
          </section>
          <section className="footer">
            <div className="footer-divider" />
            <a
              className="px-3 py-4 d-flex align-items-center"
              href="#!"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <picture className="icon">
                <img
                  src={baseUrl + WebSitesettings.Logo}
                  width="56px"
                  height="56px"
                  alt="profile"
                  style={{ width: "56px", height: "56px" }}
                />
              </picture>
              <span
                style={{
                  color: "rgb(149, 149, 149)",
                  fontSize: "1em",
                  fontWeight: 400,
                }}
                className={!open ? "d-block" : "d-none"}
              >
                {" "}
                . Terms, Privacy, Support
              </span>

              {open ? (
                <i
                  className="mdi mdi-chevron-up ml-auto"
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                ></i>
              ) : (
                <i
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                  className="mdi mdi-chevron-down ml-auto"
                ></i>
              )}
            </a>
            <Collapse in={open}>
              <div id="example-collapse-text" className="px-3 overflow-hidden">
                <div className="row footer-links">
                  <Link className="col-6" to="/term-condition">
                    Terms &amp; Condition
                  </Link>
                  <Link className="col-6" to="/PrivacyPolicy">
                    Privacy Policy
                  </Link>
                  <Link className="col-6" to="/RefundPolicy">
                    Refund/Cancellation Policy
                  </Link>
                  <Link className="col-6" to="/contact-us">
                    Contact Us
                  </Link>
                  <Link className="col-6" to="/responsible-gaming">
                    Responsible Gaming
                  </Link>
                </div>
              </div>
            </Collapse>
            <div className="footer-divider" />
            <div className="px-3 py-4">
              <div className="footer-text-bold">About Us</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} is a
                real-money gaming product owned and operated by{" "}
                {WebSitesettings ? WebSitesettings.CompanyName : ""} ("
                {WebSitesettings ? WebSitesettings.WebsiteName : ""}" or "We" or
                "Us" or "Our").
              </div>
              <br />
              <div className="footer-text-bold">
                Our Business &amp; Products
              </div>
              <br />
              <div className="footer-text">
                We are an HTML5 game-publishing company and our mission is to
                make accessing games fast and easy by removing the friction of
                app-installs.
              </div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} is a
                skill-based real-money gaming platform accessible only for our
                users in India. It is accessible on{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={WebSitesettings ? WebSitesettings.CompanyWebsite : ""}
                >
                  {WebSitesettings ? WebSitesettings.CompanyWebsite : ""}
                </a>
                . On {WebSitesettings ? WebSitesettings.WebsiteName : ""}, users
                can compete for real cash in Tournaments and Battles. They can
                encash their winnings via popular options such as Paytm Wallet,
                Amazon Pay, Bank Transfer, Mobile Recharges etc.
              </div>
              <br />
              <div className="footer-text-bold">Our Games</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} has a
                wide-variety of high-quality, premium HTML5 games. Our games are
                especially compressed and optimised to work on low-end devices,
                uncommon browsers, and patchy internet speeds.
              </div>
              <br />
              <div className="footer-text">
                We have games across several popular categories: Arcade, Action,
                Adventure, Sports &amp; Racing, Strategy, Puzzle &amp; Logic. We
                also have a strong portfolio of multiplayer games such as Ludo,
                Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese
                Checkers and more! Some of our popular titles are: Escape Run,
                Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends.
                If you have any suggestions around new games that we should add
                or if you are a game developer yourself and want to work with
                us, don't hesitate to drop in a line at{" "}
                <a
                  href={
                    WebSitesettings
                      ? "mailto:" + WebSitesettings.CompanyEmail
                      : ""
                  }
                >
                  {WebSitesettings ? WebSitesettings.CompanyEmail : ""}
                </a>
                !
              </div>
            </div>
          </section>
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
